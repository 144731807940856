<template>
  <section class="register" id="register">
    <snackbar ref="snackbar" :timeout="5000" :closeable="true" :message="validationMessage"></snackbar>
    <v-container class="fill-height">
      <div class="overlay"></div>
      <v-row class="auth-container pa-9">
        <v-col md="5" sm="12">
          <div class="d-flex flex-column align-start justify-center white--text fill-height">
            <h3 class="font-weight-bold text-left mb-3">
              Welcome to <v-icon color="white">mli-logo-horizontal</v-icon>
            </h3>
            <h6>
              The innovative platform combining the search of venues & hotels to boost group booking and ease event
              planning.
            </h6>
          </div>
        </v-col>
        <v-col md="6" offset-md="1" sm="12">
          <v-card flat outlined elevation="15" max-width="500px" class="mx-auto">
            <v-card-title>
              <v-tabs v-model="activeTab">
                <v-tab>Register</v-tab>
                <v-tab>Login</v-tab>
              </v-tabs>

              <v-tabs-items v-model="activeTab">
                <v-tab-item>
                  <v-card-text>
                    <signup @setTab="setTab"></signup>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <login></login>
                  </v-card-text>
                </v-tab-item>
              </v-tabs-items>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
const Login = () => import(/* webpackChunkName: "login" */ "./Login");
const Signup = () => import(/* webpackChunkName: "login" */ "./Signup");
import Snackbar from "@/components/Snackbar";
import { mapGetters } from "vuex";
export default {
  name: "Index",
  components: { Login, Signup, Snackbar },
  data: () => ({
    activeTab: null,
    validationMessage: ""
  }),
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  beforeRouteEnter(to, from, next) {
    next(function(vm) {
      if (vm.isAuthenticated) next({ name: "hotels" });
    });
  },
  mounted() {
    if (this.$route.name === "login") {
      this.activeTab = 1;
    }
    if (this.$route.query.password === "changed") {
      this.validationMessage = "Your password has been changed successfully. Please login to your account!";
      this.$refs.snackbar.type = "success";
      this.$refs.snackbar.timeout = 10000;
      this.$refs.snackbar.show = true;
    } else if (this.$route.query.password === "failed") {
      this.validationMessage = "Password reset token is invalid. Please resend password reset link!";
      this.$refs.snackbar.type = "error";
      this.$refs.snackbar.timeout = 10000;
      this.$refs.snackbar.show = true;
    } else if (this.$route.query.email === "verified") {
      this.validationMessage = "Your email has been verified successfully. Please login to your account!";
      this.$refs.snackbar.type = "success";
      this.$refs.snackbar.timeout = 10000;
      this.$refs.snackbar.show = true;
    }
  },
  methods: {
    setTab(value = 0) {
      if (value === 1) {
        this.activeTab = 1;
        this.validationMessage = "Thank you for registration. Please verify your email address!";
        this.$refs.snackbar.type = "success";
        this.$refs.snackbar.show = true;
      }
    }
  }
};
</script>

<style scoped>
.overlay {
  z-index: 0;
  background-color: #000000;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  opacity: 0.54;
}
.auth-container {
  z-index: 1;
}
.auth-card-width {
  width: 60%;
}
</style>
